@tailwind base;
@tailwind components;
@tailwind utilities;
body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #351e5f; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #4e0097; /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
  border: 0.1px solid #4e0097; /* creates padding around scroll thumb */
}
.react-pdf__Page__textContent  {
 background: transparent !important;
 display: none !important;
}
.custom-animation {
  animation: myAnimation 1s ease-in-out infinite;
}

@keyframes myAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/* project-card.css */
.project-card {
  transition: transform 0.3s, border-color 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
  border-color: #ad50eb;
}